import './components.scss'
import HandsUp from '../assets/images/img-5.jpg'

function Projects() {
    return (
        <div  className="container-fluid maincontainer" id="Projects">
            <div className="row rowedit"style={{display:"flex",alignItems:"center"}} >
                <div className="col-md-6 coledit "  >
                    <h3 className="h3edit headercolor">Bootcamps</h3>
                    <p className="p" > 
                        The bootcamp is structured with 3 focuses.
                    </p>
                    <li className="p">Skill Development</li>
                    <li className="p">Experiences and Widening Horizon</li>
                    <li className="p">Building Networks</li>

                    <h4 className="h4edit headercolor">Skill Development</h4>   
                    <p className="p">
                        The bootcamp provides young people with core competency skills such as leadership, teamwork and public speaking, 
                        alongside techncial skill development workshops, helping improve IT proficiency, entrepenerial 
                        curiosity and project management. These skills sessions are not only taught, but young people get to put
                        them into action through the Entrepeneurship Game ("TEG"). This game sees young people develop their own
                        businesses throughout the bootcamp, utilsiing the competency and technical skills they've developed. 
                    </p>

                    <h4 className="h4edit headercolor">Experiences and Widening Horizons</h4>
                    <p className="p">
                        We provide opportunities to widen horizions and provide new experiences through guest seminars and day visits
                        to universities, companies and recreational activities. Young people engage with doctors, 
                        lawyers, engineers, entreprenuers and teachers through our career seminar, providing an insight
                        into different career paths and work experience opportunities.
                    </p>

                    <h4 className="h4edit headercolor">Building Networks</h4>
                    <p className="p">
                        We help build networks through our mentorship programme, pairing young people with successful alumni
                        from their community. 
                
                    </p>

                    <button className="button">More Info Coming Soon</button>

                    {/*<a href="./Projects" className="button"> 
                        More Info Coming Soon
                    </a>*/}
                    
                    
                </div>
                <div className="col-md-6">
                    <img src={HandsUp}  className="programmePic" alt=".."/>
                </div>
            </div>
        </div>
    )
}
export default Projects