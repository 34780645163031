import './components.scss'
import React, { Component } from 'react';
import Carousel from 'react-elastic-carousel';
import QImage from '../assets/images/Team-Q.jpg';
import Aqeel from '../assets/images/Aqeel_Profile.jpeg';
import Ali from '../assets/images/Ali Profile.jpeg';
import Ateeya from '../assets/images/Ateeya Profile.jpeg';



function Team() {
  const breakPoints=[
    {width:1,itemsToShow:1},
    {width:558,itemsToShow:1},
    {width:768,itemsToShow:3},
    {width:1200,itemsToShow:4}
  ];

    return (
      <div className="container-fluid maincontainer" id="Team">
        <div className="row rowedit"style={{display:"flex",alignItems:"center"}}>
          <div className="Team" ></div>
            <h3 className="h3edit headercolor" >Team</h3>
            
            <Carousel breakPoints={breakPoints}>
              <div className="col">
                  <div   style={{cursor:"pointer"}} className="card h-70 shadow-sm"> 
                    <img src={Aqeel} className="card-img-top" alt="Aqeel Asif" />
                    <div className="card-body">
                      <h5 className="card-title" style={{textAlign:"center"}}>Aqeel Asif</h5>
                      <p className="p">
                        Founder of SHDP, Trainee Solicitor
                      </p>
                    </div>
                  </div>
                </div>
            
                <div className="col">
                  <div style={{cursor:"pointer"}} className="card h-70 shadow-sm">
                    <img src={Ali} className="card-img-top" alt="Ali Jawad" />
                    <div className="card-body">
                      <h5 className="card-title" style={{textAlign:"center"}}>Ali Jawad</h5>  
                      <p className="p">
                        Founder of SHDP, Computer Engineer
                      </p>
                    </div>
                  </div>
                </div>


                <div className="col">
                  <div style={{cursor:"pointer"}} className="card h-70 shadow-sm"> 
                    <img src={Ateeya} className="card-img-top" alt="Ateeya" />
                    <div className="card-body">
                      <h5 className="card-title" style={{textAlign:"center"}}>Ateeya Patel</h5>
                      <p className="p">
                        Head of Mentorship, Solicitor
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div style={{cursor:"pointer"}} className="card h-70 shadow-sm"> 
                    <img src={QImage} className="card-img-top" alt="Haider" />
                    <div className="card-body">
                      <h5 className="card-title" style={{textAlign:"center"}}>Haider Ali</h5>
                      <p className="p">
                        Head of Logistics, Student
                      </p>
                    </div>
                  </div>
                </div>
            </Carousel>
            </div>
          
        </div>
    )
    }
export default Team