import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import './page.scss'

const ProjectsPage = () => {
    return (
        <Router>
            <div className="backgroundcolor">
                <h1 className="headercolor">The Bootcamp</h1>
            </div>
        </Router>
    )
}

export default ProjectsPage
