import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import HeroSection from "../components/herosection/HeroSection";
import Footer from '../components/common/footer'; 
import Project from '../components/Projects.js'
import Team from '../components/Team.js' 
import Vision from '../components/Vision.js'
import Mission from '../components/Mission.js'
import NewBootcamp from '/Users/alijawad/Documents/GitHub/shdp/src/components/NewBootcamp.js'
import Alumni from '../components/Alumni.js'
import './page.scss'

const Home = () => {
    return (
    <Router>
        <div className="backgroundcolor">
            <HeroSection />
            <NewBootcamp />
            <Vision />
            <Mission />
            <Project />
            <Alumni />
            <Team />
            <Footer/>
        </div>
    </Router>
    )
}

export default Home
