import 'bootstrap/dist/css/bootstrap.min.css'
import { Header } from './components/common'
import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home'
import Projects from "./pages/Projects"
import ContactUs from './pages/Contact';

import Navbar from './components/common/navbar';
import Footer from './components/common/footer';

function App() {
  return (
    <div className="App">      
      <Navbar/>
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/contact-us" component={ContactUs} exact/>
        <Route path="/projects" component={Projects} exact/>
      </Switch>
    </div>
  );
}

export default App;
