import React from 'react';
import './Section.scss';


function Section(props) {

  return (
    <div id={props.id} className='default-container'>
           
      <h1>{props.id}</h1>
      {props.customBody}
      <p>Example Text</p>
    </div>
  );
}

export default Section;
