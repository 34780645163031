import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';

const ContactUs = () => {
    return (
    <Router>
        
        <h1>THIS IS CONTACT PAGE</h1>
    </Router>
    )
}

export default ContactUs