import React, {useState} from 'react';
import '../../App.scss';
import { Button } from '../common';
import './HeroSection.scss';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import logo from '../../assets/images/logo.png'

function HeroSection() {
  const [isOpen, setOpen] = useState(false)

  return (
    <div id='hero' className='hero-container'>
      
            
      <div className="logoPlaceholder">
        <img src={logo} className="logoEdit"/>
      </div>
      
      <div className='headerPlaceholder'>
   
        <p>Small Heath Development Programme </p>
        <p>Providing young people with the skills, experiences, and network to unlock their potential</p>

        
      </div>
      
      

      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          
          <button className="btn--primary" onClick={()=> setOpen(true)}>
            WATCH TRAILER 
            <i className='far fa-play-circle'></i> 
          </button>
          <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="HsLH1dOBKLw" onClose={() => setOpen(false)} />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
