import './components.scss'
import Poster from '../assets/images/SHDP-1080x1080-final.png'

function NewBootcamp() {
    return (
        <div className="container-fluid maincontainer" id="NewBootcamp">
            <div className="row rowedit" style={{display:"flex", alignItems:"center"}} >
                <div className="col-md-6">
                    <img src={Poster} className="missionPic" alt='' />
                </div>

                <div className="col-md-6 missionEdit">
                    
                    <h3 className="h3edit missionmargin headercolor">SHDP Bootcamp</h3>

                    <h5 className="h5edit headercolor">The Bootcamp has Returned for 2022/2023</h5>
                    <p className="p">
                        Do you want to improve your skills, experience, and network?
                    </p>

                    <p className="p">
                        Are you aged 14-16?
                    </p>

                    <p className="p">
                        Sign up to the Small Heath Development Programme to take part in a 
                        unique opportunity to gain an insight into a wide range of careers, with 
                        talks and sessions with industry leaders.
                    </p>
                    <p className="p">
                        We will be running Monthly sessions on Saturday's from the 22<sup>nd</sup>
                        October until March 2023. Click the link below to sign up!
                    </p>

                    <a href ="https://forms.gle/cJhijvb612cCsnAL9" className="button">
                        Click Here To Sign Up
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NewBootcamp