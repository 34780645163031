import './components.scss'
import AlishaAndHaider from '../assets/images/Egg dropp 2.JPG'

function Alumni() {
    return (
        <div className="container-fluid maincontainer" id="Alumni">
            <div className="row rowedit" style={{display:"flex", alignItems:"center"}}>
                <div className="col-md-6">
                    <img src={AlishaAndHaider} className="missionPic"/>
                </div>
                <div className="col-md-6 missionEdit">
                    <h3 className="h3edit headercolor">
                        Almuni
                    </h3>

                    <p className="p">
                        The SHDP Alumni is designed for people who have attended previous Bootcamps and
                        will give them a chance to reconnect with others.
                    </p>

                    <p className="p">
                        More Information about the Alumni programme to come
                    </p>

                    <button className="button">More Info Coming Soon</button>
                    

                </div>
            
            </div>
        </div>
    )
}
export default Alumni