import React, { useState, useEffect } from 'react';
import '../../common/navbar/Navbar.scss';
import { Button } from '../../common/';
// import { Link } from 'react-router-dom';
import 'react-modal-video/scss/modal-video.scss';
import { Link } from 'react-scroll';
import logo from '../../../assets/images/logo.png';

function Navbar (){
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 1085) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  
 window.addEventListener('resize', showButton)

  return (
        <>
        <div className="navbar">
          <div className="container-fluid" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Link to='hero' smooth={true} duration={500} offset={-80} className="navbar-logo" onClick={closeMobileMenu}>
            <img className="fab fa-typo3" src={logo}/>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}> 
              <li className='nav-item'> 
                <Link to='hero'   smooth={true} duration={500} offset={-80}  className='nav-links' onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>          
                         
              <li className='nav-item' to='about' smooth={true} duration={1000} > 
              <Link to='Vision' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  What We Do
                </Link>
              </li>

              <li className='nav-item'> 
                <Link to='Mission' smooth={true} className='nav-links' offset={-80} onClick={closeMobileMenu}>
                  The Issue
                </Link>
              </li>
              
              <li className='nav-item'>   
              <Link to='Projects' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  Bootcamp
                </Link>
              </li>

              <li className='nav-item'>   
              <Link to='Alumni' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  Alumni
                </Link>
              </li>

              <li className='nav-item'> 
              <Link to='Team' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  Team
                </Link>
              </li>
              
              {/*<li className='nav-item'> 
              <Link to='Contact' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  Get Involved
                </Link>
              </li>*/}

              <li className='nav-item'> 
              <Link to='Contact' smooth={true} duration={500} offset={-80} className='nav-links' onClick={closeMobileMenu}>
                  Contact
                </Link>
              </li>

             
              </ul>
          </div>
        </div>
        </>
    );


}
export default Navbar;

