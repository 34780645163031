import React, {useState} from "react";
import  './Footer.scss';


import { FaFacebookSquare, FaInstagramSquare, FaLinkedinIn, FaTwitterSquare } from "react-icons/fa";


function Footer (){
    

    return (
        
    <div className="main-footer" id="Contact">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <h4>Small Heath Development Programme</h4>
            <h1 className="list-unstyled">
              <li>Khidmat Centre</li>
              <li>2A Heather Road</li>
              <li>Small Heath</li>
              <li>Birmingham</li>
              <li>B10 9TE</li>
            </h1>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Contact</h4>
            <ui className="list-unstyled">
              <li>admin@theshdp.org</li>
              <li>engagement@theshdp.org</li>
            </ui>

            <h4>Socials</h4>
            <ui className="list-unstyled-social">
              <a href="https://www.facebook.com/theshdp/"><FaFacebookSquare/></a>
              <a href="https://instagram.com/theshdp?utm_medium=copy_link"><FaInstagramSquare/></a>
              <a href="https://www.linkedin.com/company/small-heath-development-programme"><FaLinkedinIn/></a>
            </ui>
            
          </div>


          {/* Column3 */}
          <div className="col">
            <h4 className="h4edit">Partners</h4>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} Small Heath Development Programme
          </p>
        </div>
      </div>
    </div>
        )
}
export default Footer;