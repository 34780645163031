import './components.scss'
import Trailer from '../assets/videos/Trailer1.mp4'
import Aqeel from '../assets/images/img-2.jpg'
import ShahidBro from '../assets/images/Intro to coding session.JPG'
import AlishaAndHaider from '../assets/images/Egg dropp 2.JPG'

function Vision() {
    return (
        <div  className="container-fluid maincontainer" id="Vision">
            <div className="row rowedit"style={{display:"flex",alignItems:"center"}} >
                <div className="col-md-6 coledit" >
                    {/*<h3 className="h3edit headercolor">Who We Are</h3>
                    <p className="p"> 
                        The SHDP is a not-for-profit organisation that was founded with the goal of increasing social 
                        mobility for some of the UK's most deprived young people
                    </p>*/}
                    
                    <h3 className="h3edit headercolor">Who We Are</h3>

                    <p className="p" > 
                        The SHDP aims to equip young people (aged 14-16) from disadvantaged backgrounds with the
                        skills, experiences, and network to unlock their potential. 
                    </p>

                    <p className="p">    
                        The programme targets young people from low socio-economic backgrounds, providing both the
                        competency and technical skills which will help create a sustainable route to alleviate poverty.
                        This includes skills such as leadership, teamwork, coding, entrepreneurship and marketing.
                        This will be coordinated alongside experience-building seminars, engaging young people with new
                        career paths, opportunities and networks. This is sustained through
                        a mentorship programme, partnering successful alumni from similar communites with
                        SHDP candidates.  
                    </p> 

                    <p className="p">
                        Research highlights that children from poorer backgrounds suffer from a lack in skill development,
                        opportunities and network, compared to wealthier peers. This manifests itself in a generationation-wide
                        gap between entire communities. SHDP aims to reduce this gap, by affording young people from historically
                        impoverished backgrounds, the opportunities to maximise their potential. 
                    </p> 

                    <p className="p">
                        Since our inception in Small Heath, SHDP has expanded to Croydon, London; an area with similar levels of 
                        endemic poverty. We have run two successful bootcamps in 2020, and 2021, with the aim of running a third
                        successive bootcamp in 2022, in Small Heath. 
                    </p>

                    <p className="p">
                        This organisation was created through the partnership of Small Heath Community Forum and the National 
                        Lottery Community Fund. 
                    </p>                
                </div>
                
                
                <div className="col-md-6">
                    <img src={ShahidBro} className="visionPic"/>
                </div>

                {/*<div className="col-md-6">
                    <img src={AlishaAndHaider} className="visionPic"/>
                </div>*/} 


            </div>
        </div>
    )}
export default Vision