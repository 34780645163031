import './components.scss'
import Alisha from '../assets/images/img-6.jpg';

function Mission() {
    return (
    <div  className="container-fluid maincontainer" id="Mission">
        <div className="row rowedit" style={{display:"flex",alignItems:"center"}} >
            <div className="col-md-6" >
                <img src={Alisha} className="missionPic" alt='' />
            </div>
            <div className="col-md-6 missionEdit"  >
                <h3 className="h3edit missionmargin headercolor">The Issue</h3>
                
                <h4 className="h4edit headercolor">Small Heath</h4>
                <p className="p">
                    Small Heath is one of the most deprived and impoverished communities in the UK and ranks in the top 10% 
                    of the most impoverished areas in the UK.
                </p>
                <p className="p">
                    These high levels of poverty help to foster the low levels of education, employement and community
                    engagement in the community.
                </p>
                <p className="p">
                    This drove us to develop the SHDP, as a means of tackling this issue of endemic poverty and closing
                    the gap between the wealthiest and poorest children.
                </p>

                <h4 className="h4edit headercolor">Croydon</h4>
                    

                
            </div>
        </div>
    </div>
    )
}
export default Mission